<template>
   <v-layout align-start>
      <v-flex xs12>
         <!-- DIALOG CREAR / EDITAR  -->
         <v-dialog v-model="dialogCreateEdit" persistent max-width="500px">
            <v-form
               ref="formAranceles"
               v-model="validAranceles"
               lazy-validation
            >
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm3>
                              <v-text-field
                                 v-model="codigo"
                                 :readonly="editedIndex != -1"
                                 :disabled="editedIndex != -1"
                                 :rules="rulesCodigo"
                                 color="primary"
                                 label="Código"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm9>
                              <v-text-field
                                 v-model="nombre"
                                 color="primary"
                                 label="Nombre"
                                 :rules="rulesNombre"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="precio"
                                 color="primary"
                                 label="Precio"
                                 :rules="rulesPrecio"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="comodin"
                                 color="primary"
                                 label="Comodín"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12>
                              <v-autocomplete
                                 v-model="selectPublico"
                                 color="primary"
                                 item-value="id"
                                 item-text="descripcion"
                                 :items="cmbPublico"
                                 label="Público"
                              ></v-autocomplete>
                           </v-flex>
                           <v-flex xs12 sm4>
                              <v-switch
                                 v-model="aplicaBeca"
                                 color="primary"
                                 label="Aplica beca"
                              ></v-switch>
                           </v-flex>
                           <v-flex xs12 sm4>
                              <v-switch
                                 v-model="porCantidad"
                                 color="primary"
                                 label="Por cantidad"
                              ></v-switch>
                           </v-flex>
                           <v-flex xs12 sm4>
                              <v-switch
                                 v-model="requiereFacultad"
                                 color="primary"
                                 label="Req. facultad"
                              ></v-switch>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-textarea
                                 v-model="descripcion"
                                 color="primary"
                                 rows="4"
                                 auto-grow
                                 outlined
                                 :rules="rulesDescripcion"
                                 label="Descripción"
                              ></v-textarea>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validAranceles"
                        @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Aranceles</v-toolbar-title
               >
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
               <v-btn text icon color="white darken-3" @click="nuevoArancel()">
                  <v-icon>fas fa-plus</v-icon>
               </v-btn>
            </v-toolbar>

            <!-- DATATABLE aranceles -->
            <v-data-table
               :headers="headers"
               :items="aranceles"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #item.publico="{ item }">
                  {{
                     item.publico == null
                        ? 'Ambos'
                        : item.publico
                        ? 'Externos'
                        : 'Alumnos'
                  }}
               </template>

               <template #item.aplicaBeca="{ item }">
                  <v-tooltip
                     v-if="item.aplicaBeca"
                     top
                     max-width="300"
                     color="primary"
                  >
                     <template #activator="{ on }">
                        <v-icon color="primary" v-on="on"
                           >fas fa-toggle-on</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> Si </span>
                  </v-tooltip>
                  <v-tooltip v-else top max-width="300" color="grey darken-1">
                     <template #activator="{ on }">
                        <v-icon color="grey" v-on="on"
                           >fas fa-toggle-off</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> No </span>
                  </v-tooltip>
               </template>

               <template #item.porCantidad="{ item }">
                  <v-tooltip
                     v-if="item.porCantidad"
                     top
                     max-width="300"
                     color="primary"
                  >
                     <template #activator="{ on }">
                        <v-icon color="primary" v-on="on"
                           >fas fa-toggle-on</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> Si </span>
                  </v-tooltip>
                  <v-tooltip v-else top max-width="300" color="grey darken-1">
                     <template #activator="{ on }">
                        <v-icon color="grey" v-on="on"
                           >fas fa-toggle-off</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> No </span>
                  </v-tooltip>
               </template>

               <template #item.requiereFacultad="{ item }">
                  <v-tooltip
                     v-if="item.requiereFacultad"
                     top
                     max-width="300"
                     color="primary"
                  >
                     <template #activator="{ on }">
                        <v-icon color="primary" v-on="on"
                           >fas fa-toggle-on</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> Si </span>
                  </v-tooltip>
                  <v-tooltip v-else top max-width="300" color="grey darken-1">
                     <template #activator="{ on }">
                        <v-icon color="grey" v-on="on"
                           >fas fa-toggle-off</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> No </span>
                  </v-tooltip>
               </template>

               <template #item.comodin="{ item }">
                  {{
                     item.comodin == null || item.publico == ''
                        ? 'N/A'
                        : item.comodin
                  }}
               </template>

               <template #item.opciones="{ item }">
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span>Editar</span>
                  </v-tooltip>
               </template>

               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="aranceles.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import formatters from '../mixins/formattersLib'

export default {
   name: 'Aranceles',
   mixins: [formatters],

   data: () => ({
      // Variables
      dialogCreateEdit: false,
      editedIndex: -1,
      headers: [
         { text: 'Código', value: 'codigo', class: 'titulo' },
         { text: 'Nombre', value: 'nombre', class: 'titulo', width: '350px' },
         { text: 'Precio', value: 'precio', class: 'titulo' },
         {
            text: 'Comodin',
            value: 'comodin',
            class: 'titulo'
         },
         {
            text: 'Público',
            value: 'publico',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Aplica beca',
            value: 'aplicaBeca',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Por cantidad',
            value: 'porCantidad',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Requiere facultad',
            value: 'requiereFacultad',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      aranceles: [],

      validAranceles: true,

      // Propiedades aranceles
      alumnoArancelPeriodoId: '',
      codigo: '',
      nombre: '',
      descripcion: '',
      precio: '',
      aplicaBeca: false,
      porCantidad: false,
      requiereFacultad: false,
      selectPublico: false,
      cmbPublico: [
         {
            id: null,
            descripcion: 'Ambos'
         },
         {
            id: true,
            descripcion: 'Externos'
         },
         {
            id: false,
            descripcion: 'Alumnos'
         }
      ],
      comodin: '',

      // Reglas de validacion de formularios
      rulesCodigo: [(v) => !!v || 'Ingrese un código'],
      rulesNombre: [(v) => !!v || 'Ingrese un nombre'],
      rulesPrecio: [(v) => !!v || 'Ingrese un precio'],
      rulesDescripcion: [(v) => !!v || 'Ingrese una descripción']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1 ? 'Nuevo arancel' : 'Editar arancel'
      },
      // Descripcion de publico
      publicoDescripcion() {
         return this.cmbPublico.find((obj) => {
            return obj.id === this.selectPublico
         }).descripcion
      }
   },
   created() {
      if (this.permController(15, 'ver')) {
         this.listarAranceles()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      // Select an item
      assingItem(item) {
         this.codigo = item.codigo
         this.nombre = item.nombre
         this.descripcion = item.descripcion
         this.precio = item.precio
         this.aplicaBeca = item.aplicaBeca
         this.porCantidad = item.porCantidad
         this.requiereFacultad = item.requiereFacultad
         this.comodin = item.comodin
         this.selectPublico = item.publico
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarAranceles() {
         // Obteniendo aranceles
         axios
            .get('api/Arancel')
            .then((response) => {
               this.aranceles = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las aranceles en el datatable se puede resetear
      reset() {
         this.listarAranceles()
      },

      // Cargando informacion del item a editar
      editItem(item) {
         this.assingItem(item)
         this.editedIndex = 1
         this.dialogCreateEdit = true
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogCreateEdit = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar aranceles
      save() {
         if (this.$refs.formAranceles.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               axios
                  .put('api/Arancel/codigo?codigo=' + this.codigo, {
                     codigo: this.codigo,
                     nombre: this.nombre,
                     descripcion: this.descripcion,
                     precio: this.precio,
                     aplicaBeca: this.aplicaBeca,
                     publico: this.selectPublico,
                     porCantidad: this.porCantidad,
                     requiereFacultad: this.requiereFacultad,
                     comodin: this.comodin
                  })
                  .then(() => {
                     this.listarAranceles()
                     this.close()
                     this.$store.dispatch('showSnack', {
                        message: 'Arancel editado exitosamente!',
                        color: 'snackSuccess'
                     })
                  })
                  .catch((error) => {
                     if (error.response.status == 304) {
                        this.$store.dispatch('showSnack', {
                           message: 'Sin cambios.',
                           color: 'snackInfo'
                        })
                     } else {
                        this.$store.dispatch('showSnack', {
                           message: 'Algo ha ido mal...',
                           color: 'snackError'
                        })
                     }
                     this.close()
                  })
            } else {
               // Codigo para guardar
               axios
                  .post('api/Arancel', {
                     codigo: this.codigo,
                     nombre: this.nombre,
                     descripcion: this.descripcion,
                     precio: this.precio,
                     aplicaBeca: this.aplicaBeca,
                     publico: this.selectPublico,
                     porCantidad: this.porCantidad,
                     requiereFacultad: this.requiereFacultad,
                     comodin: this.comodin
                  })
                  .then(() => {
                     this.listarAranceles()
                     this.close()
                     this.$store.dispatch('showSnack', {
                        message: 'Arancel agregado exitosamente!',
                        color: 'snackSuccess'
                     })
                  })
                  .catch(() => {
                     this.$store.dispatch('showSnack', {
                        message:
                           'No se pudo crear el arancel. Revisar si el código ingresado no esta en uso.',
                        color: 'snackError'
                     })
                  })
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formAranceles.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.codigo = ''
         this.nombre = ''
         this.descripcion = ''
         this.precio = ''
         this.aplicaBeca = false
         this.porCantidad = false
         this.requiereFacultad = false
         this.comodin = ''
         this.selectPublico = false
      },
      nuevoArancel() {
         this.dialogCreateEdit = true
      }
   }
}
</script>
